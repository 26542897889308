@for (toast of toastService.toasts; track toast) {
<ngb-toast
  [class]="toast.classname"
  [autohide]="toast.autohide"
  [delay]="toast.delay || 5000"
  (hidden)="toastService.remove(toast)"
  (mouseenter)="pauseToast(toast)"
  (mouseleave)="resumeToast(toast)"
>
  <div class="toast-header">
    <span
      class="me-2"
      [ngClass]="{
        'bi-check-circle-fill':
          toast.classname?.includes('bg-success'),
        'bi-exclamation-circle-fill':
          toast.classname?.includes('bg-danger')
      }"
    ></span>
    <strong class="me-auto">
      {{ toast.classname?.includes("bg-success") ? "Success" : "Error" }}
    </strong>
    <button
      type="button"
      class="btn-close"
      aria-label="Close"
      (click)="toastService.remove(toast)"
    ></button>
  </div>
  <div class="toast-body text-white">
    <ng-template [ngTemplateOutlet]="toast.template"></ng-template>
  </div>
</ngb-toast>
}
